import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  FormRow,
  eventService,
} from "@commonninja/commonninja-styleguide-react";
import { H3 } from "@commonninja/commonninja-styleguide-react/lib/esm/components/internal";

import { IAuthProps } from "./auth.types";
import { AuthLayout } from "./authLayout.comp";
import { authService } from "./auth.service";
import { AuthForm } from "./authForm.comp";
import { clearRedirectUrl } from "../../helpers/helpers";
import { SocialLogin } from "./socialLogin.comp";
import { MFAPopup } from "./mFApopup.comp";

// declare let gtag: Function;
// declare let ga: Function;

export const Login = ({ embedMode, isDeveloper = false }: IAuthProps) => {
  const { search } = useLocation();
  const { redirectUrl } = queryString.parse(search);
  const firstInput: any = useRef();
  const [formState, setFormState] = useState<any>({
    username: "",
    password: "",
  });
  const [isShowMFAPopup, setIsShowMFAPopup] = useState<boolean>(false);

  function formFieldChanged(propName: string, value: any) {
    setFormState({
      ...formState,
      [propName]: propName === "username" ? value.toLowerCase() : value,
    });
  }

  function postLoginCheckMFA(isMFAEnabled?: boolean) {
    if (isMFAEnabled) {
      setIsShowMFAPopup(true);
    } else {
      postLoginRedirect();
    }
  }

  function mFaSuccessCallback() {
    setIsShowMFAPopup(false);
    postLoginRedirect();
  }

  function postLoginRedirect() {
    // Post message to parent
    if (embedMode) {
      window.parent.postMessage(
        {
          type: "COMMONNINJA_AUTH_SUCCESS",
          json: {},
        },
        "*"
      );

      return;
    }

    // Redirect to redirectUrl based on query string
    if (redirectUrl && clearRedirectUrl(redirectUrl as string)) {
      window.location.href = decodeURIComponent(redirectUrl as string);
      return;
    }

    // Fallback to user dashboard
    window.setTimeout(() => {
      if (isDeveloper) {
        window.location.href = "/developer/apps";
      } else {
        window.location.href = "/user/dashboard";
      }
    }, 100);
  }

  useEffect(() => {
    document.title = `Log in | Common Ninja`;

    eventService.reportMixpanelEvent(`Enter Login`, {
      authenticated: false,
    });

    window.setTimeout(function () {
      window.parent.postMessage(
        {
          type: "COMMONNINJA_DASHBOARD_IFRAME_UPDATE",
          height: (document.querySelector("#main-content") as HTMLBodyElement)
            .clientHeight,
        },
        "*"
      );
    }, 100);
  }, []);

  return (
    <>
      {isShowMFAPopup && (
        <MFAPopup
          successCallback={mFaSuccessCallback}
          onClose={() => setIsShowMFAPopup(false)}
          email={formState.username}
        />
      )}
      <AuthLayout isDeveloper={isDeveloper} embedMode={embedMode}>
        <H3>Hey Ninja!</H3>
        <p>Welcome back. Please sign in to continue.</p>
        <SocialLogin
          successCallback={postLoginRedirect}
          isDeveloper={isDeveloper}
        />
        <div className="or">Or</div>

        <AuthForm
          name="login"
          mainButtonText="Log In"
          footerComp={
            <NavLink
              to={`${embedMode ? "/embed" : ""}${
                isDeveloper ? "/developer" : ""
              }/forgot${search ? search : ""}`}
              className="primary"
            >
              Forgot Password?
            </NavLink>
          }
          embedMode={embedMode}
          secondaryButtonComp={
            <div className="center">
              Don’t have an account?{" "}
              <NavLink
                to={`${embedMode ? "/embed" : ""}${
                  isDeveloper ? "/developer" : ""
                }/signup${search ? search : ""}`}
                className="primary"
              >
                Sign Up
              </NavLink>
            </div>
          }
          authRequest={(captchaToken) =>
            authService.login(formState, captchaToken)
          }
          firstInput={firstInput}
          successCallback={postLoginCheckMFA}
        >
          <FormRow flow="column">
            <input
              type="email"
              ref={firstInput}
              value={formState.username}
              placeholder="Enter email address"
              onChange={(e) => formFieldChanged("username", e.target.value)}
              minLength={4}
              required
            />
          </FormRow>
          <FormRow flow="column">
            <input
              type="password"
              placeholder="Password"
              value={formState.password}
              onChange={(e) => formFieldChanged("password", e.target.value)}
              minLength={6}
              maxLength={60}
              required
            />
          </FormRow>
        </AuthForm>
      </AuthLayout>
    </>
  );
};
