import React, { useRef } from "react";
import { FaChevronDown } from "react-icons/fa";
import { IAppPlanFeature } from "../../developer/developer.types";
import { Price } from "../price/price.comp";
import { Discount } from "../discount/discount.comp";
import { CheckoutButton } from "../checkoutButton/checkoutButton.comp";
import { IPluginListingNew } from "../../../services/appList.service";
import { IPricingData } from "../newPricing.comp";
import { Tooltip } from "@commonninja/commonninja-styleguide-react";

export const FeaturesComparison = (props: {
  activeVariantId: number;
  embedMode: boolean;
  websiteMode: boolean;
  apps: IPluginListingNew[];
  serviceName: string;
  pricingData: IPricingData;
  onLoading: (loading: boolean, type?: "checkout" | "update") => void;
  extendedPricingData?: IPricingData;
  featureSections?: {
    name: string;
    features: IAppPlanFeature[];
  }[];
}) => {
  const {
    pricingData: { plans, features },
    activeVariantId,
    serviceName,
    featureSections,
    extendedPricingData,
  } = props;
  const ref = useRef<any>();

  function scrollToTable() {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  function renderStarSvg(checked: boolean) {
    if (checked) {
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 20 20"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          className="checked"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          ></path>
        </svg>
      );
    }
    return (
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3578 3.74235L12.6009 9.49987L18.3578 15.2571C19.2141 16.1138 19.2141 17.5014 18.3578 18.3581C17.9299 18.7859 17.369 19 16.8083 19C16.2467 19 15.6857 18.7862 15.2582 18.3581L9.50003 12.6001L3.74232 18.358C3.31452 18.7859 2.75352 18.9999 2.19236 18.9999C1.63136 18.9999 1.07074 18.7862 0.642559 18.358C-0.21375 17.5017 -0.21375 16.114 0.642559 15.2571L6.39928 9.49981L0.642232 3.74235C-0.214077 2.88603 -0.214077 1.49806 0.642232 0.641745C1.49838 -0.213915 2.88552 -0.213915 3.74199 0.641745L9.49997 6.39927L15.2575 0.641745C16.1142 -0.213915 17.5015 -0.213915 18.3574 0.641745C19.2141 1.49806 19.2141 2.88603 18.3578 3.74235Z"
          fill="#f0f0f0"
        ></path>
      </svg>
    );
  }

  function renderFeature(feature: IAppPlanFeature) {
    return (
      <div className="pricing-table-content" key={`group_${feature.name}`}>
        <h5 className="feature-name">
          {feature.displayName}
          {feature.description && (
            <Tooltip content={feature.description} direction="top" />
          )}
        </h5>
        {plans.map((plan) => {
          let value = plan.features[feature.name] ?? feature.value;
          if (
            feature.name === "numberOfViewsPerMonth" &&
            typeof value === "number" &&
            value > 10000000
          ) {
            value = "Unlimited";
          }

          if (
            feature.name === "numberOfBytesInStorage" &&
            typeof value === "number"
          ) {
            // If the value is lower than a GB, we should show it in MB
            if (value < 1000 * 1000 * 1000) {
              value = `${(value / (1000 * 1000))} MB`;
            } else {
              value = `${(value / (1000 * 1000 * 1000))} GB`;
            }
          }

          if (
            serviceName === "widgetsbundle" &&
            feature.name === "websites" &&
            !plan.isFree
          ) {
            value = "Unlimited";
          }

          if (
            serviceName === "widgetsbundle" &&
            feature.name === "allApps" &&
            !plan.isFree
          ) {
            value = true;
          }

          if (value === 0) {
            value = "Default";
          }

          return (
            <p
              className={`feature-value ${value ? "checked" : ""}`}
              key={`p_${plan.name}_${feature.name}`}
            >
              {" "}
              {typeof value === "boolean"
                ? renderStarSvg(value)
                : typeof value === "number"
                ? value.toLocaleString()
                : value}
            </p>
          );
        })}
      </div>
    );
  }

  function renderPlanHeader(name: string, skipPlans = false) {
    return (
      <header>
        <div className={`group-title`}>
          <h4>{name}</h4>
        </div>
        {!skipPlans &&
          plans.map((plan) => {
            return (
              <div
                className={`plan-container ${plan.name?.toLowerCase()}`}
                key={plan.name}
              >
                <h6 className="title">{plan.name}</h6>
                <div
                  className={`plan-price-wrapper ${plan.name?.toLowerCase()}`}
                >
                  <Price plan={plan} activeVariantId={activeVariantId} />
                  <div>
                    <Discount plan={plan} activeVariantId={activeVariantId} />
                  </div>
                </div>
                <div className="checkout-buttons-wrapper">
                  <CheckoutButton
                    {...props}
                    pricingData={extendedPricingData || props.pricingData}
                    plan={plan}
                  />
                </div>
              </div>
            );
          })}
      </header>
    );
  }

  return (
    <div className="comparison-table-wrapper">
      <button
        onClick={scrollToTable}
        className={`full-plan-btn ${
          navigator?.userAgent?.includes("safari") ? "browser-safari" : ""
        }`}
      >
        Features & Plans Comparison <FaChevronDown size={20} />
      </button>

      <main ref={ref} className="comparison-table-overflow ">
        <div className="comparison-table">
          {!featureSections ? (
            <>
              {renderPlanHeader("Features")}
              <section className="pricing-table-content-wrapper">
                {features
                  .filter((f) => f.meta?.type !== "app")
                  .map(renderFeature)}
              </section>
              {serviceName === "bundles" && (
                <>
                  {renderPlanHeader("Apps Included", true)}
                  <section className="pricing-table-content-wrapper">
                    {features
                      .filter((f) => f.meta?.type === "app")
                      .map(renderFeature)}
                  </section>
                </>
              )}
            </>
          ) : (
            featureSections.map((section, idx) => {
              return (
                <React.Fragment key={`section-${idx}`}>
                  {renderPlanHeader(section.name, idx > 0)}
                  <section className="pricing-table-content-wrapper">
                    {section.features.map(renderFeature)}
                  </section>
                </React.Fragment>
              );
            })
          )}
        </div>
      </main>
    </div>
  );
};
