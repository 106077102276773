import React, { useEffect, useState } from "react";
import {
  FaCheck,
  // FaCheck,
  // FaPen,
  // FaPlus,
  FaShoppingCart,
  FaStar,
  FaTrash,
} from "react-icons/fa";
import cn from "classnames";
import {
  Button,
  HttpService,
  Tooltip,
  eventService,
  plansService,
} from "@commonninja/commonninja-styleguide-react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { IAppPlan, IAppPlanFeature } from "../../developer/developer.types";
import { IPluginListingNew } from "../../../services/appList.service";
import { IPricingData } from "../newPricing.comp";
import { Price } from "../price/price.comp";
import { Discount } from "../discount/discount.comp";
import { PricingTable } from "../pricingTable/pricingTable.comp";
import { CycleSelector } from "../cycleSelector/cycleSelector.comp";
import { MiniBundlesPopup } from "../miniBundlesPopup/miniBundlesPopup.comp";
import { FeaturesComparison } from "../featuresComparison/featuresComparison.comp";
import credit from "../../../assets/credit.png";
import { renderSword, renderThrowingStars } from "../svgElm/svgElm.comp";

import "./miniBundles.scss";
import { CustomPlan } from "../customPlan/customPlan.comp";
import { IUserSubscription } from "../../subscriptions/subscriptions.comp";
import { WarnPopup } from "../../developer/common/warnPopup/warnPopup.comp";

const nindoApiUrl = process.env.REACT_APP_NINDO_SERVICE_URL || "";

export const MiniBundles = ({
  pricingData,
  miniBundlesPricingData,
  apps: allApps,
  embedMode,
  websiteMode,
  serviceName,
  activeVariantId,
  onLoading,
  setActiveServiceName,
  setActiveVariantId,
  userSubscriptions,
  setUserSubscriptions,
}: {
  apps: IPluginListingNew[];
  pricingData: IPricingData;
  miniBundlesPricingData: IPricingData;
  activeVariantId: number;
  websiteMode: boolean;
  embedMode: boolean;
  serviceName: string;
  onLoading: (loading: boolean, type?: "checkout" | "update") => void;
  setActiveServiceName: (serviceName: string) => void;
  setActiveVariantId: (variantId: number) => void;
  userSubscriptions: IUserSubscription[];
  setUserSubscriptions: (userSubscriptions: IUserSubscription[]) => void;
}) => {
  const {
    params: { serviceName: originalServiceName },
  } = useRouteMatch() as any;
  const apps = allApps.filter(
    (app) =>
      app.status === "published" &&
      app.serviceName !== "bracketsninja" &&
      app.serviceName.includes("ninja")
  );
  const [bundlesPopupOpened, setBundlesPopupOpened] = useState<boolean>(false);
  const [useCreditPopupOpened, setUseCreditPopupOpened] =
    useState<boolean>(false);
  const { user } = useSelector((state: any) => ({ user: state.user }));
  const history = useHistory();
  const { plans, features } = miniBundlesPricingData;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedApps, setSelectedApps] = useState<string[]>(
    serviceName.includes("ninja") ? [serviceName] : []
  );
  const [popupOpened, setPopupOpened] = useState<boolean>(false);
  const [activePlanIdx, setActivePlanIdx] = useState<number>(0);
  const [localActiveServiceName, setLocalActiveServiceName] =
    useState<string>(serviceName);
  const plan = plans[activePlanIdx];
  const limit = plan.features["numberOfApps"];
  const appsLeft = limit - selectedApps.length;
  const widgetSuffix = Math.abs(appsLeft) > 1 ? "s" : "";
  const activeVariant = plan.pricingVariants[activeVariantId] || {};
  const hasNextPlan = !!plans[activePlanIdx + 1];
  const miniBundlesServiceName = "minibundles";
  const singleAppPrice = 15;
  const userHasAllInOne =
    serviceName !== "bundles" &&
    userSubscriptions.some(
      (s) => s.plan.services.includes("bundles") && s.status === "active"
    );
  const userMiniBundle = userSubscriptions.find(
    (s) => s.status === "active" && s.plan.services.includes("minibundles")
  );
  const appIsInMiniBundle =
    userMiniBundle?.miniBundleDetails?.selectedApps.includes(
      localActiveServiceName
    );

  async function createMiniBundle() {
    const client = new HttpService();
    const result = await client.makeRequest(
      `${nindoApiUrl}/nindo/api/createMiniBundle?${client.queryParams}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ selectedApps }),
      }
    );

    if (!result.success) {
      throw new Error(result.message || "Cannot create bundle.");
    }
  }

  async function updateMiniBundle(id: string, selectedApps: string[]) {
    try {
      const client = new HttpService();
      const result = await client.makeRequest(
        `${nindoApiUrl}/nindo/api/updateMiniBundle/${id}?${client.queryParams}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ selectedApps }),
        }
      );

      if (!result.success) {
        throw new Error(result.message || "Cannot update bundle.");
      }
    } catch (e) {
      console.error("Cannot update bundle", e);
    }
  }

  async function redirectToCheckoutUrl(
    pricingVariantId: string,
    platform: string,
    price: number,
    localServiceName: string
  ) {
    try {
      onLoading(true, "checkout");
      toast.dismiss();

      let componentType = apps.filter(
        (a) => a.serviceName === localServiceName
      )?.[0]?.componentType;

      if (serviceName === "bundles") {
        componentType = "bundle";
      } else if (serviceName === "minibundles") {
        componentType = "mini_bundles";
      }

      const client = new HttpService();
      const result = await client.makeRequest(
        `${nindoApiUrl}/nindo/api/getCheckoutUrl/${componentType}?${client.queryParams}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pricingVariantId, platform }),
        }
      );

      if (!result.success) {
        throw new Error(result.message || "Cannot get checkout url.");
      }

      const checkoutUrl = result.data; // Returns the checkout url
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      let redirectUrl = `${baseUrl}/embed/${
        websiteMode ? "website/" : ""
      }checkout-success/${localServiceName}?price=${price}`; // Post successful checkout url

      if (platform === "shopify") {
        const parentUrl = new URL(window.location.href).searchParams.get(
          "origUrl"
        );
        if (parentUrl) {
          redirectUrl = decodeURIComponent(parentUrl);
        }
      }

      let finalUrl = `${checkoutUrl}${
        checkoutUrl.includes("?") ? "&" : "?"
      }redirectUrl=${encodeURIComponent(redirectUrl)}`;

      // For Rewardy referrals program
      const referral = (window as any)?.Rewardful?.referral;
      if (referral) {
        finalUrl += `&referral=${referral}`;
      }

      eventService.reportMixpanelEvent(`Checkout Start`, {
        authenticated: user.isAuthenticated,
        serviceName: localServiceName,
      });

      if (websiteMode && window.top) {
        window.top.location.href = finalUrl;
      } else {
        window.location.href = finalUrl;
      }
    } catch (e) {
      toast.error((e as Error).message);
      onLoading(false);
    }
  }

  function renderArrow() {
    return (
      <span className="arrow-wrapper">
        Save 50%
        <svg
          className="arrow"
          width="111"
          height="27"
          viewBox="0 0 111 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_751_11670"
            maskUnits="userSpaceOnUse"
            x="66"
            y="0"
            width="45"
            height="27"
          >
            <rect x="66" width="45" height="27" fill="#fff"></rect>
          </mask>
          <g>
            <path
              d="M68.2396 7.83206C71.1254 7.17304 77.2449 7.78657 82.4579 10.2425C87.1427 12.4491 91.4171 15.9211 95.1247 19.5378C92.6427 18.9292 92.273 18.9144 89.9738 18.6285C87.6384 18.3379 86.4325 22.0609 88.2396 22.5093C90.6699 23.1125 96.0695 24.5258 99.0737 25.4497C99.8751 25.6963 101.655 26.718 102.897 24.8213C103.437 23.9957 103.716 22.9089 103.039 22.179C102.856 21.7725 99.2888 13.1597 98.6222 10.6835C98.2869 9.43736 96.3194 10.6905 96.6574 11.9474C97.0816 13.523 97.3951 15.1298 97.7311 16.7298C90.8792 10.1668 81.7516 4.2621 72.2769 5.25438C70.92 5.39634 69.5679 5.73903 68.2405 6.04195C67.3225 6.25123 66.9945 8.11739 68.24 7.83272L68.2396 7.83206Z"
              fill="#fff"
            ></path>
          </g>
        </svg>
      </span>
    );
  }

  function renderMiniBundlesCheckoutButton() {
    // For non registered users
    if (!user?.isAuthenticated) {
      return (
        <Button
          color="transparent"
          onClick={() => {
            const loginUrl = `${
              embedMode && !websiteMode ? "/embed" : ""
            }/signup?serviceName=${serviceName}`;
            let redirectUrl = `${
              embedMode ? "/embed" : ""
            }/pricing/${serviceName}`;
            if (!websiteMode) {
              history.push(
                `${loginUrl}&redirectUrl=${encodeURIComponent(redirectUrl)}`
              );
              return;
            }

            // For website mode, we need to redirect to the pricing page for this plugin on website
            const pluginDetails = apps.find(
              (p) => p.serviceName === serviceName
            );
            redirectUrl = `/widgets/${pluginDetails?.slug}/pricing`;
            if (window.top) {
              window.top.location.href = `${loginUrl}&redirectUrl=${encodeURIComponent(
                redirectUrl
              )}`;
            }
          }}
        >
          Sign Up
        </Button>
      );
    }

    return (
      <Button
        disabled={loading || appsLeft < 0}
        onClick={async () => {
          setLoading(true);

          try {
            await createMiniBundle();

            const platform = "paddle";

            plansService.reportFunnelEvent("PRICING_PLAN_SELECT");
            plansService.reportFunnelEvent("PRICING_PLAN_SUBMIT");
            eventService.reportMixpanelEvent("Click select plan", {
              miniBundlesServiceName,
              authenticated: user.isAuthenticated,
              embedMode,
            });
            eventService.reportMixpanelEvent(
              `Click subscribe with ${platform}`,
              {
                miniBundlesServiceName,
                authenticated: user.isAuthenticated,
                embedMode: embedMode,
              }
            );

            const currentVariant = plan.pricingVariants[activeVariantId];

            await redirectToCheckoutUrl(
              currentVariant.id,
              platform,
              currentVariant.priceCount,
              miniBundlesServiceName
            );
          } catch (e) {
            toast.error((e as Error).message);
          }

          setLoading(false);
        }}
      >
        {loading ? (
          "Loading..."
        ) : (
          <>
            <FaShoppingCart />
            Checkout
          </>
        )}
      </Button>
    );
  }

  function renderMiniBundlesBody() {
    return (
      <>
        {renderCreditsLeft()}
        <div className="mini-bundles-main-section">
          <div className="mini-bundles-pricing mini-bundles-box">
            {renderThrowingStars()}
            <h3>
              <Price plan={plan} activeVariantId={activeVariantId} />
            </h3>
            <p className="billing-period">
              Billed {activeVariant.name}{" "}
              <Discount plan={plan} activeVariantId={activeVariantId} />
            </p>
            <ul>
              {features.map((feature: IAppPlanFeature) => {
                let value = plan.features[feature.name] ?? feature.value;
                if (
                  feature.name === "numberOfViewsPerMonth" &&
                  typeof value === "number" &&
                  value > 10000000
                ) {
                  value = "Unlimited";
                }

                if (
                  feature.name === "numberOfBytesInStorage" &&
                  typeof value === "number"
                ) {
                  // If the value is lower than a GB, we should show it in MB
                  if (value < 1000 * 1000 * 1000) {
                    value = `${(value / (1000 * 1000))} MB`;
                  } else {
                    value = `${(value / (1000 * 1000 * 1000))} GB`;
                  }
                }

                return (
                  <li key={`feat-${feature.name}`}>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 20 20"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      className="checked"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    {value} {feature.displayName}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="mini-bundles-selected-apps-wrapper mini-bundles-box">
            {renderSword()}
            <h4>
              {/* Selected Apps ({selectedApps.length})
              <FaPen
                title="Add or change apps"
                onClick={() => setPopupOpened(true)}
              /> */}
              Cart
            </h4>
            <div
              className="mini-bundles-selected-app credits"
              key={`sel-wid-${serviceName}`}
            >
              <img src={credit} alt={"Credits left"} />
              <h5>
                {appsLeft > 0 ? (
                  <>
                    <span className="credits-left">
                      {appsLeft} App Credit{appsLeft === 1 ? "" : "s"}{" "}
                      <Tooltip
                        width={290}
                        content={
                          "One <strong>app credit</strong> equals to one app in Common Ninja. You can checkout now, and select the apps later from the dashboard."
                        }
                      />
                    </span>
                    <span
                      className="select-apps"
                      onClick={() => setPopupOpened(true)}
                    >
                      Select Apps
                    </span>
                  </>
                ) : (
                  <>
                    <span className="credits-left">
                      0 credits left
                      <Tooltip
                        width={290}
                        content={
                          "One <strong>app credit</strong> equals to one app in Common Ninja. You can checkout now, and select the apps later from the dashboard."
                        }
                      />
                    </span>
                    <span
                      className="select-apps"
                      onClick={() => setPopupOpened(true)}
                    >
                      Change Apps Selection
                    </span>
                  </>
                )}
              </h5>
              <div className="mini-bundles-selected-app-mask">
                <span className="orig-price">
                  ${appsLeft < 0 ? 0 : appsLeft * singleAppPrice}
                </span>
                <span className="new-price">
                  $
                  {appsLeft < 0
                    ? 0
                    : appsLeft * singleAppPrice -
                      appsLeft * singleAppPrice * 0.5}
                </span>
              </div>
            </div>
            <div className="mini-bundles-selected-apps">
              {selectedApps.map((serviceName: string) => {
                const app = apps.find((app) => app.serviceName === serviceName);
                if (!app) {
                  return <></>;
                }
                return (
                  <div
                    className="mini-bundles-selected-app"
                    key={`sel-wid-${serviceName}`}
                  >
                    <img src={app.logo} alt={app.name} />
                    <h5>
                      <span className="credits-left">
                        {app.name}{" "}
                        <FaTrash
                          onClick={() => {
                            setSelectedApps(
                              selectedApps.filter((s) => s !== serviceName)
                            );
                          }}
                          className="trash"
                        />
                      </span>
                    </h5>
                    <div className="mini-bundles-selected-app-mask">
                      <span className="orig-price">${singleAppPrice}</span>
                      <span className="new-price">
                        ${singleAppPrice - singleAppPrice * 0.5}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mini-bundles-checkout">
              <div className="mini-bundles-checkout-summary">
                <div className="mini-bundles-checkout-summary-item">
                  <span>Sub total</span>
                  <span>${limit * singleAppPrice}</span>
                </div>
                <div className="mini-bundles-checkout-summary-item">
                  <span>Bundle discount</span>
                  <span className="discount">
                    -${limit * singleAppPrice * 0.5}
                  </span>
                </div>
                <div className="mini-bundles-checkout-summary-item">
                  <span>Annual discount</span>
                  <span className="discount">
                    {activeVariantId === 1
                      ? `-$${limit * singleAppPrice * 0.5 * 0.2}`
                      : "$0"}
                  </span>
                </div>
                <div className="mini-bundles-checkout-summary-item">
                  <span>Total</span>
                  <span className="final-price">
                    <Price
                      plan={plan}
                      activeVariantId={activeVariantId}
                      disableFloating={true}
                    />
                    <span className="small">Billed {activeVariant.name}</span>
                  </span>
                </div>
              </div>
              {appsLeft < 0 && (
                <p>
                  <span className="error-message">
                    Please remove {Math.abs(appsLeft)} app{widgetSuffix} {` `}
                    or upgrade to a higher plan
                  </span>
                </p>
              )}
              {renderMiniBundlesCheckoutButton()}
            </div>
            {/* {appsLeft > 0 && (
              <Button onClick={() => setPopupOpened(true)}>
                <FaPlus />
                Add {appsLeft} more app{widgetSuffix}
              </Button>
            )} */}
          </div>
        </div>
        <CustomPlan />
        <MiniBundlesPopup
          popupOpened={popupOpened}
          setPopupOpened={setPopupOpened}
          initialSelectedApps={selectedApps}
          limit={limit}
          apps={apps}
          onSelect={(apps: string[]) => setSelectedApps(apps)}
          moveToNextPlan={
            hasNextPlan ? () => setActivePlanIdx(activePlanIdx + 1) : undefined
          }
          allowLimitPass={true}
        />
      </>
    );
  }

  function renderAllInOneMessage() {
    return (
      <div className="mini-bundles-main-section">
        <div className="mini-bundles-pricing mini-bundles-box plan-message">
          <FaCheck />
          <h4>
            You already own{" "}
            {localActiveServiceName === "minibundles" ? "all apps" : "this app"}
            !
          </h4>
          <p>
            {localActiveServiceName ? "All apps are" : "This app is already"}{" "}
            included in your All-in-One plan.
          </p>
          <Button
            color="transparent"
            onClick={() => {
              setLocalActiveServiceName("bundles");
            }}
          >
            See Pricing
          </Button>
        </div>
      </div>
    );
  }

  function renderAppIncludedMessage() {
    return (
      <div className="mini-bundles-main-section">
        <div className="mini-bundles-pricing mini-bundles-box plan-message">
          <FaCheck />
          <h4>You already own this app!</h4>
          <p>
            This app is already included in your
            <br /> {userMiniBundle?.plan.features["numberOfApps"]} apps bundle
            plan.
          </p>
          <Button
            color="transparent"
            onClick={() => {
              setBundlesPopupOpened(true);
            }}
          >
            Edit Bundle
          </Button>
        </div>
      </div>
    );
  }
  function renderCreditsLeft() {
    if (
      !userMiniBundle?.miniBundleDetails ||
      localActiveServiceName === "bundles"
    ) {
      return <></>;
    }

    const { selectedApps } = userMiniBundle.miniBundleDetails;
    const credits = userMiniBundle?.plan.features["numberOfApps"];
    const creditsLeft = credits - selectedApps.length;

    if (creditsLeft <= 0) {
      return <></>;
    }

    return (
      <div className="mini-bundles-credits-left">
        <div className="mini-bundles-credits-left-content">
          <img src={credit} alt={"Credits left"} />
          <span>
            Hey ninja, you have <strong>{creditsLeft}</strong> unused credits!
            {localActiveServiceName === "minibundles"
              ? " Would you like to use them instead of purchasing another bundle?"
              : " Would you like to use one to get this app?"}
          </span>
        </div>
        <Button
          onClick={() => {
            if (localActiveServiceName === "minibundles") {
              setBundlesPopupOpened(true);
              return;
            }
            setUseCreditPopupOpened(true);
          }}
        >
          Use Credit
        </Button>
      </div>
    );
  }

  function renderPlanBody() {
    if (userHasAllInOne) {
      return renderAllInOneMessage();
    }

    if (localActiveServiceName === "minibundles") {
      return renderMiniBundlesBody();
    }

    if (appIsInMiniBundle) {
      return renderAppIncludedMessage();
    }

    return (
      <>
        {renderCreditsLeft()}
        <PricingTable
          activeVariantId={activeVariantId}
          apps={allApps}
          embedMode={embedMode}
          websiteMode={websiteMode}
          onLoading={onLoading}
          pricingData={pricingData}
          serviceName={serviceName}
        />
      </>
    );
  }

  useEffect(() => {
    setActiveServiceName(localActiveServiceName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localActiveServiceName]);

  useEffect(() => {
    const userMiniBundle = userSubscriptions.find((s) =>
      s.plan.services.includes("minibundles")
    );
    const appIsInMiniBundle =
      userMiniBundle?.miniBundleDetails?.selectedApps.includes(
        originalServiceName
      );
    if (appIsInMiniBundle) {
      setSelectedApps(selectedApps.filter((s) => s !== originalServiceName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSubscriptions]);

  return (
    <>
      <div className="mini-bundles-wrapper">
        <div className="mini-bundles-plans mini-bundles-box">
          {originalServiceName !== "bundles" && (
            <div
              className={cn("mini-bundles-plan", {
                selected: localActiveServiceName === originalServiceName,
              })}
              onClick={() => {
                eventService.reportMixpanelEvent(`Select Bundle Type`, {
                  authenticated: user.isAuthenticated,
                  bundleType: "1 App",
                  serviceName: originalServiceName,
                });
                setLocalActiveServiceName(originalServiceName);
              }}
            >
              1 App
            </div>
          )}
          {plans.map((plan: IAppPlan, index: number) => {
            return (
              <div
                className={cn("mini-bundles-plan", {
                  selected:
                    activePlanIdx === index &&
                    localActiveServiceName === "minibundles",
                })}
                key={`plan-${plan.planId}`}
                onClick={() => {
                  eventService.reportMixpanelEvent(`Select Bundle Type`, {
                    authenticated: user.isAuthenticated,
                    bundleType: plan.name,
                    serviceName: originalServiceName,
                  });
                  setActivePlanIdx(index);
                  setLocalActiveServiceName("minibundles");
                }}
              >
                {plan.name}
                {index === 0 &&
                  originalServiceName !== "bundles" &&
                  localActiveServiceName === originalServiceName &&
                  renderArrow()}
                {index === 1 &&
                  localActiveServiceName === "minibundles" &&
                  activePlanIdx === 0 &&
                  renderArrow()}
              </div>
            );
          })}
          <div
            className={cn("mini-bundles-plan all-in-one", {
              selected: localActiveServiceName === "bundles",
            })}
            onClick={() => {
              eventService.reportMixpanelEvent(`Select Bundle Type`, {
                authenticated: user.isAuthenticated,
                bundleType: "All-in-One",
                serviceName: originalServiceName,
              });
              setLocalActiveServiceName("bundles");
            }}
          >
            <FaStar /> All-in-One
          </div>
        </div>
        <CycleSelector
          activeVariantId={activeVariantId}
          setActiveVariantId={setActiveVariantId}
          plans={
            localActiveServiceName === "minibundles"
              ? miniBundlesPricingData.plans
              : pricingData.plans
          }
          serviceName={localActiveServiceName}
        />
        {renderPlanBody()}
      </div>
      <MiniBundlesPopup
        popupOpened={bundlesPopupOpened}
        setPopupOpened={setBundlesPopupOpened}
        initialSelectedApps={
          userMiniBundle?.miniBundleDetails?.selectedApps || []
        }
        limit={userMiniBundle?.plan?.features?.numberOfApps || 3}
        apps={apps.filter((a) => a.componentType !== "bracket")}
        onSelect={async (apps: string[]) => {
          await updateMiniBundle(
            userMiniBundle?.miniBundleDetails?.id || "",
            apps
          );
          setUserSubscriptions(
            userSubscriptions.map((s) => {
              if (
                s.miniBundleDetails?.id ===
                userMiniBundle?.miniBundleDetails?.id
              ) {
                (s.miniBundleDetails as any).selectedApps = apps;
              }
              return s;
            })
          );
        }}
        allowLimitPass={false}
      />
      {localActiveServiceName !== "minibundles" &&
        !userHasAllInOne &&
        !appIsInMiniBundle && (
          <FeaturesComparison
            activeVariantId={activeVariantId}
            apps={apps}
            embedMode={embedMode}
            websiteMode={websiteMode}
            onLoading={onLoading}
            pricingData={pricingData}
            serviceName={serviceName}
          />
        )}
      <WarnPopup
        showPopup={useCreditPopupOpened}
        setShowPopup={setUseCreditPopupOpened}
        title="Would you like to use a credit to get this app?"
        text={`Confirm your selection to use a credit for this app from our collection. Remember, you can always change your selection if you change your mind.`}
        buttonText="Use Credit"
        onConfirm={() => {
          const { id = "", selectedApps = [] } =
            userMiniBundle?.miniBundleDetails || {};
          const nextSelectedApps = [...selectedApps, localActiveServiceName];
          updateMiniBundle(id, nextSelectedApps);
          setUserSubscriptions(
            userSubscriptions.map((s) => {
              if (s.miniBundleDetails?.id === id) {
                s.miniBundleDetails.selectedApps = nextSelectedApps;
              }
              return s;
            })
          );
          setUseCreditPopupOpened(false);
        }}
      />
    </>
  );
};
