import React from "react";
import { IAppPlan, IAppPlanFeature } from "../../developer/developer.types";
import { CustomPlan } from "../customPlan/customPlan.comp";
import { useRouteMatch } from "react-router-dom";
import { CheckoutButton } from "../checkoutButton/checkoutButton.comp";
import { Button, Tooltip } from "@commonninja/commonninja-styleguide-react";
import { IPluginListingNew } from "../../../services/appList.service";
import { Price } from "../price/price.comp";
// import { Discount } from "../discount/discount.comp";
import { IPricingData } from "../newPricing.comp";
import { renderSword, renderThrowingStars } from "../svgElm/svgElm.comp";
import { v4 } from "uuid";
import { FaChevronDown } from "react-icons/fa";

export const PricingTable = ({
  activeVariantId,
  embedMode,
  websiteMode,
  apps,
  onLoading,
  pricingData,
  serviceName,
  showCheckoutButton = true,
  showDecorations = true,
  showOnlyDiffs = false,
  extendedPricingData,
}: {
  activeVariantId: number;
  embedMode: boolean;
  websiteMode: boolean;
  apps: IPluginListingNew[];
  serviceName: string;
  pricingData: IPricingData;
  onLoading: (loading: boolean, type?: "checkout" | "update") => void;
  extendedPricingData?: IPricingData;
  showDecorations?: boolean;
  showOnlyDiffs?: boolean;
  showCheckoutButton?: boolean;
}) => {
  const {
    params: { vendor },
  } = useRouteMatch() as any;
  const { plans, features } = pricingData;
  const minNumberOfFeaturesToShow = 6;

  function getHighlightedFeatures(): IAppPlanFeature[] {
    const localFeutures = features.map((feature, idx) => ({
      ...feature,
      idx,
    }));
    const localHighlightedFeatures: IAppPlanFeature[] = [];

    localFeutures.forEach((feature) => {
      if (!feature.meta?.highlight) {
        return;
      }
      localHighlightedFeatures.push(feature);
    });

    // Bring the first 5 as a fallback
    localFeutures.forEach((feature, idx) => {
      if (localHighlightedFeatures.length >= minNumberOfFeaturesToShow) {
        return;
      }

      if (localHighlightedFeatures.find((f) => f.name === feature.name)) {
        return;
      }

      localHighlightedFeatures.push(feature);
    });

    return localHighlightedFeatures.sort((a: any, b: any) => a.idx - b.idx);
  }

  function getPlanFeatures(plan: IAppPlan, idx: number): IAppPlanFeature[] {
    const previousPlan = plans[idx - 1];

    return [
      ...(previousPlan
        ? [
          {
            id: v4(),
            description: "",
            displayName: previousPlan
              ? `Everything in ${previousPlan.name}`
              : "Free features",
            meta: {},
            name: "everythingInPreviousPlan",
            type: "string",
            value: true,
          },
        ]
        : ([] as any)),
      ...(idx === 1
        ? [
          {
            id: v4(),
            description: "",
            displayName: "Access 200+ widget types",
            meta: {},
            name: "allApps",
            type: "boolean",
            value: true,
          },
        ]
        : []),
      ...features.filter((feature) => {
        // if (
        //   feature.meta.group !== "Apps" &&
        //   feature.meta.group !== "Core Features"
        // ) {
        //   return false;
        // }

        // if (feature.name === "trial") {
        //   return true;
        // }

        // if (feature.type !== "boolean") {
        //   return true;
        // }

        if (feature.name === 'imageUploads' || feature.name === 'trial') {
          return false;
        }

        if (
          !feature.meta?.highlight &&
          feature.meta.group !== "Core Features"
        ) {
          return false;
        }

        if (!previousPlan) {
          if (feature.type === "number" || !!plan.features[feature.name]) {
            return true;
          }
          return false;
        }

        if (
          feature.type === "boolean" &&
          previousPlan?.features?.[feature.name]
        ) {
          return false; // Don't show features from previous plans
        }

        if (
          previousPlan?.features?.[feature.name] === plan.features[feature.name]
        ) {
          return false; // Don't show features from previous plans
        }

        return !!plan.features[feature.name];
      }),
      {
        id: v4(),
        description: "",
        displayName: (
          <span
            className="scroll-to-comparison-table"
            onClick={() =>
              document
                .querySelector(".comparison-table")
                ?.scrollIntoView({ behavior: "smooth" })
            }
          >
            {plan.name} features <FaChevronDown />
          </span>
        ),
        meta: {},
        name: plan.name,
        type: "string",
        value: true,
      },
    ];
  }

  function renderV(v: boolean) {
    if (!v) {
      return (
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={"unchecked"}
        >
          <path
            d="M10.6282 2.16662L7.29525 5.49992L10.6282 8.83307C11.1239 9.32902 11.1239 10.1324 10.6282 10.6283C10.3805 10.8761 10.0557 11 9.73112 11C9.40598 11 9.08119 10.8762 8.83368 10.6283L5.50002 7.29482L2.16661 10.6283C1.91893 10.876 1.59414 11 1.26926 11C0.944471 11 0.619902 10.8762 0.372008 10.6283C-0.12375 10.1326 -0.12375 9.32918 0.372008 8.83304L3.70485 5.49989L0.371818 2.16662C-0.123939 1.67086 -0.123939 0.867297 0.371818 0.371536C0.867481 -0.123845 1.67056 -0.123845 2.16642 0.371536L5.49998 3.70484L8.8333 0.371536C9.32925 -0.123845 10.1324 -0.123845 10.628 0.371536C11.1239 0.867297 11.1239 1.67086 10.6282 2.16662Z"
            fill="#A9A9A9"
          />
        </svg>
      );
    }
    return (
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 20 20"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        className={"checked"}
      >
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }

  function renderAgencyPlanBox(highlightedFeatures: IAppPlanFeature[]) {
    return (
      <div className={`mini-box agency}`}>
        {showDecorations && renderSword()}
        <div className="title">Agency</div>
        <div className={`plan-price-wrapper`}>Contact us</div>
        <p className={`per-month-txt`}>Custom pricing</p>
        <div className="checkout-buttons-wrapper">
          <Button
            color="transparent"
            onClick={() => {
              window.open(
                "https://commoninja.site/custom-plan",
                "_blank"
              );
            }}
          >
            Contact Sales
          </Button>
        </div>
        <main>
          {highlightedFeatures.map((feature) => {
            let value = feature.value;
            if (typeof value === "number") {
              value = "Custom";
            } else if (typeof value === "boolean") {
              value = true;
            }

            return (
              <div key={`highlighted_feature_${feature.name}`}>
                {renderV(!!(value || typeof value === "number"))}
                {value === "Custom" && <span>{value}</span>}
                <span
                  className={`feature-display ${value || typeof value === "number" ? "" : "unchecked"
                    }`}
                >
                  {feature.displayName}
                </span>
              </div>
            );
          })}
        </main>
      </div>
    );
  }

  const highlightedFeatures: IAppPlanFeature[] = getHighlightedFeatures();

  return (
    <div className="new-pricing-table-wrapper">
      <div className="pricing-table">
        {plans.map((plan, idx) => {
          const activeVariant = plan.pricingVariants[activeVariantId] || {};
          const planFeatures = showOnlyDiffs
            ? getPlanFeatures(plan, idx)
            : highlightedFeatures;

          return (
            <div
              className={`mini-box ${plan.name?.toLowerCase()}`}
              key={`mini_plan_${plan.planId}`}
            >
              <div className="inner-content">
                {idx === 0 && showDecorations && renderThrowingStars()}
                {showDecorations &&
                  serviceName !== "bundles" &&
                  idx === plans.length - 1 &&
                  renderSword()}
                <div className="title">{plan.name}</div>
                <div className={`plan-price-wrapper ${plan.name?.toLowerCase()}`}>
                  <Price plan={plan} activeVariantId={activeVariantId} />
                </div>
                <p className={`per-month-txt ${plan.name?.toLowerCase()}`}>
                  {plan.isFree
                    ? "No charge."
                    : `Billed ${activeVariant.pricePeriod === "year"
                      ? "$" + activeVariant.priceCount.toLocaleString() + " "
                      : ""
                    }${activeVariant.name?.toLowerCase()} `}
                  {/* <Discount plan={plan} activeVariantId={activeVariantId} /> */}
                </p>
                {showCheckoutButton && (
                  <div className="checkout-buttons-wrapper">
                    <CheckoutButton
                      activeVariantId={activeVariantId}
                      apps={apps}
                      embedMode={embedMode}
                      onLoading={onLoading}
                      plan={plan}
                      pricingData={extendedPricingData || pricingData}
                      serviceName={serviceName}
                      websiteMode={websiteMode}
                    />
                  </div>
                )}
                <main>
                  {planFeatures.map((feature) => {
                    let value = plan.features[feature.name] ?? feature.value;
                    if (
                      feature.name === "numberOfViewsPerMonth" &&
                      typeof value === "number" &&
                      value > 10000000
                    ) {
                      value = "Unlimited";
                    }

                    if (
                      feature.name === "numberOfBytesInStorage" &&
                      typeof value === "number"
                    ) {
                      // If the value is lower than a GB, we should show it in MB
                      if (value < 1000 * 1000 * 1000) {
                        value = `${(value / (1000 * 1000))} MB`;
                      } else {
                        value = `${(value / (1000 * 1000 * 1000))} GB`;
                      }
                    }

                    if (
                      serviceName === "widgetsbundle" &&
                      feature.name === "websites" &&
                      !plan.isFree
                    ) {
                      value = "Unlimited";
                    }

                    if (
                      serviceName === "widgetsbundle" &&
                      feature.name === "allApps" &&
                      !plan.isFree
                    ) {
                      value = true;
                    }

                    return (
                      <div key={`highlighted_feature_${feature.name}`}>
                        {renderV(!!(value || typeof value === "number"))}
                        <span className="value">
                          {typeof value === "number"
                            ? value.toLocaleString()
                            : value}
                        </span>
                        <span
                          className={`feature-display ${value || typeof value === "number" ? "" : "unchecked"
                            }`}
                        >
                          {feature.displayName}
                        </span>
                        {feature.description && (
                          <Tooltip
                            content={feature.description}
                            direction="top"
                          />
                        )}
                      </div>
                    );
                  })}
                </main>
              </div>

            </div>
          );
        })}
        {serviceName === "bundles" &&
          showCheckoutButton &&
          !vendor &&
          renderAgencyPlanBox(highlightedFeatures)}
      </div>
      {showCheckoutButton && <CustomPlan />}
    </div>
  );
};
