import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PricingLoader } from '@commonninja/commonninja-styleguide-react/lib/esm/components/internal';
import {
  IPlan,
  IPricingModel,
  plansService,
  eventService,
  useQuery,
} from '@commonninja/commonninja-styleguide-react';
import { appList, IPluginListingNew } from '../../services/appList.service';

import { Checkout } from '../checkout/checkout.comp';

import './pricing.scss';

export const OldPricing = ({ embedMode, websiteMode }: any) => {
  const history = useHistory();
  let {
    params: { serviceName, vendor },
  } = useRouteMatch() as any;
  if (vendor === "nindo" || vendor === "website") {
    vendor = "";
  }
  const [localServiceName, setServiceName] = useState(
    serviceName || 'bracketsninja',
  );
  const { user } = useSelector((state: any) => ({ user: state.user }));
  const [apps, setApps] = useState<IPluginListingNew[]>([]);
  const [currentStage, setCurrentStage] = useState<
    'pricing' | 'checkout' | 'post-checkout'
  >('pricing');
  const [planData, setPlanData] = useState<null | IPricingModel>(null);
  const [activeSubscription, setActiveSubscription] = useState<null | any>(
    null,
  );
  const [selectedPlan, setSelectedPlan] = useState<null | {
    plan: IPlan;
    changeMode: boolean;
    activeCycleId: number;
  }>(null);
  const query = useQuery();

  async function loadApps() {
    setApps(await appList.get());
  }

  function renderBody() {
    if (currentStage === 'pricing') {
      return (
        <PricingLoader
          initialServiceName={serviceName}
          user={user}
          onServiceChange={(nextServiceName: string) => {
            const qs = vendor ? `?${query.toString()}` : '';
            setServiceName(nextServiceName);
            let url = '';
            if (vendor) {
              url = `/embed/v/${vendor}`;
            } else if (websiteMode) {
              url = `/embed/website`;
            } else if (embedMode) {
              url = `/embed`;
            }
            history.push(`${url}/pricing/${nextServiceName}${qs}`);
          }}
          onPlanSelect={async (plan, activeCycleId, changeMode) => {
            if (user.isAuthenticated) {
              plansService.reportFunnelEvent('PRICING_PLAN_SELECT');
              eventService.reportMixpanelEvent('Click select plan', {
                serviceName: localServiceName,
                authenticated: user.isAuthenticated,
                embedMode,
              });

              const planName = plan.name?.toLowerCase();
              if (planName === 'free') {
                alert('Please cancel the subscription through the dashboard.');
              } else {
                setSelectedPlan({
                  plan,
                  changeMode,
                  activeCycleId,
                });
              }
              return;
            }

            const loginUrl = `${
              embedMode && !websiteMode ? '/embed' : ''
            }/signup?serviceName=${localServiceName}`;
            let redirectUrl = `${
              embedMode ? '/embed' : ''
            }/pricing/${localServiceName}`;
            if (!websiteMode) {
              history.push(
                `${loginUrl}&redirectUrl=${encodeURIComponent(redirectUrl)}`,
              );
              return;
            }

            // For website mode, we need to redirect to the pricing page for this plugin on website
            const pluginDetails = apps.find(
              (p) => p.serviceName === localServiceName,
            );
            redirectUrl = `/widgets/${pluginDetails?.slug}/pricing`;
            if (window.top) {
              window.top.location.href = `${loginUrl}&redirectUrl=${encodeURIComponent(
                redirectUrl,
              )}`;
            }
          }}
          onPlanDataLoad={(planData: IPricingModel | null) => {
            setPlanData(planData);
          }}
          onUserSubscriptionLoad={(subscriptionData: any) => {
            setActiveSubscription(subscriptionData);
          }}
          rewriteDocumentTitle={true}
          showServiceSelector={!embedMode}
          vendor={vendor}
        />
      );
    }

    if (planData && selectedPlan && currentStage === 'checkout') {
      return (
        <Checkout
          user={user}
          embedMode={embedMode}
          websiteMode={websiteMode}
          pricingModel={planData}
          activePlan={selectedPlan?.plan}
          activeCycle={planData?.cycles[selectedPlan?.activeCycleId || 0]}
          activeSubscription={activeSubscription}
          checkoutMode={selectedPlan?.changeMode ? 'change' : 'create'}
          returnCallback={() => setCurrentStage('pricing')}
        />
      );
    }

    return <></>;
  }

  useEffect(() => {
    if (selectedPlan) {
      setCurrentStage('checkout');
    } else {
      setCurrentStage('pricing');
    }
  }, [selectedPlan]);

  useEffect(() => {
    loadApps();
    // eslint-disable-next-line
  }, []);

  return renderBody();
};
